import React from "react";
import "../../assets/styles/Pages_css/Dashbord.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  return (
    <>
      <div className="services_maintence_first_help">
        <Navbar />
        <Helmet>
          <title>Solaire</title>

          <meta name="description" content="Solaire" />
        </Helmet>
        <div>
          <div className="services">
            <h1 className="py-5">Aide</h1>
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6  col-sm-6">
                  <div className="box">
                    <h3>N1</h3>
                    <p>
                      information generale sur l'utilisation de a plat forme
                    </p>
                  </div>
                </div>
                {/*  */}
                <div className="col-lg-3 col-md-6  col-sm-6">
                  <div className="box">
                    <h3> N2</h3>
                    <p>Lorem Ipsum </p>
                  </div>
                </div>
                {/*  */}
                <div className="col-lg-3 col-md-6  col-sm-6">
                  <div className="box">
                    <h3> N3</h3>
                    <p>Lorem Ipsum </p>
                  </div>
                </div>
                {/*  */}
                <div className="col-lg-3 col-md-6  col-sm-6">
                  <div className="box">
                    <h3> N3</h3>
                    <p>Lorem Ipsum </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Dashboard;
