

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};
 // Vous pouvez personnaliser le gestionnaire de rapports selon vos besoins
export default reportWebVitals;

// function sendToAnalytics(metric) {
//   const { name, value } = metric;

//   // Customize the Matomo tracking URL and site ID based on your configuration
//   const matomoUrl = 'https://trackmyroof.matomo.cloud//matomo.php';
//   // const siteId = 'your-matomo-site-id';

//   // Construct the data to be sent to Matomo
//   const data = {
//     e_c: 'Web Vitals',
//     e_a: name,
//     e_n: value,
//     // idsite: siteId,
//     rec: 1, // Set to 1 to force a pageview
//   };

//   // Send the data to Matomo
//   const url = `${matomoUrl}?${new URLSearchParams(data).toString()}`;

//   // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
//   if (navigator.sendBeacon) {
//     navigator.sendBeacon(url);
//   } else {
//     fetch(url, { method: 'GET', keepalive: true });
//   }
// }

// export function reportWebVitals(metric) {
//   sendToAnalytics(metric);
// }
// function sendToAnalytics(metric) {
//   const body = JSON.stringify(metric);
//   const url = 'https://trackmyroof.matomo.cloud//matomo.php';

//   // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
//   if (navigator.sendBeacon) {
//     navigator.sendBeacon(url, body);
//   } else {
//     fetch(url, { body, method: 'POST', keepalive: true });
//   }
// }

// reportWebVitals(sendToAnalytics);