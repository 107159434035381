import api from "./api";
const userService = {
  signup: async (firstname, surname, number, email, password, privileges) => {
    const user = { email, password, firstname, surname, number, privileges };
    return await api.post("/users/register", user);
  },
  login: async (email, password) => {
    const user = { email, password };
    return await api.post("/users/login", user);
  },
  forgotPassword: async (email) => {
    const user = { email };
    return await api.post("password/forgot-password", user);
  },
  sendEmailContact: async (email,name, first_name, adresse, phone, description,subject) => {
    const emails = { email, name, first_name, adresse, phone, description, subject };
    return await api.post("/password/send/email/contact", emails);
  },
  restPassword: async (token, password1, password2) => {
    const user = { token, password1, password2 };
    return await api.post("password/rest-password", user);
  },
  chekRobot: async (token) => {
    const user = {token};
    return await api.post("/users/post", user);
  },
  updatePassword: async (password) => {
    return await api.put("user", { password });
  },
  getById: async ({ id: id_User }) => {
    return await api.get(`users/data/user/${id_User}`);
  },
};
export default userService;
