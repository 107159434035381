import React from "react";
import "./spanStyles.css";
const NoMaintenance = () => {
  return (
    <div className="Nomaintenace">
      <div className="Nomaintenace_contenu">
        <h3>Vous avez pas de maintenace</h3>
      </div>
    </div>
  );
};
export default NoMaintenance;
