import api from "./api.js";
const dataService = {
  downlod: async ( site_id,mindate ,maxdate) => {
    const allDatas = { site_id,mindate ,maxdate};
    return await api.post("datas/download/data/site",allDatas);
  },
  downlod2: async ( site_id, groupe_id, eolienne_id,mindate ,maxdate) => {   
    const allDatas = {site_id, groupe_id, eolienne_id, mindate ,maxdate};
    return await api.post("datas/download/data/eolienne", allDatas);
  },
};
export default dataService;