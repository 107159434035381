import React, { Fragment } from "react";
import { toast } from "react-toastify";
import "../../assets/styles/Pages_css/profileUser.css";
import "../../assets/styles/Pages_css/Politique.css";
import "react-toastify/dist/ReactToastify.css";
// import Navbar from "../../components/Navbar/Navbar";

toast.configure();
const PolitiqueProtectionDonnees = () => {
  return (
    <Fragment>
      <main className="main">
        {/* <Navbar /> */}

        <div className="user-view">
          <aside className="menu ">
            <ul className="side-nav">
              <div class="col-md-8 ">
                <ul class="list-group text-light">
                  <li class="list ">Mentions légales & CGU</li>
                  <li class="list">Politique de protection des données</li>
                  <li class="list">votre adresse mail</li>
                </ul>
              </div>
            </ul>
          </aside>
          <aside className="user-content text-light p-2">
            <div class="col-md-8">
              <div class="col-md-8">
                <h2>Qui sommes-nous ?</h2>
                <p>
                  L’adresse de notre site Web est : https://windmyroof.com. Ce
                  site Web est géré par la société WIND my ROOF SAS au capital
                  social de 3428,60€, enregistrée au Registre du Commerce et des
                  Sociétés de Créteil sous le numéro 840718225.
                </p>
              </div>
              <h2>Utilisation des données personnelles collectées</h2>
              <h4>FORMULAIRES DE CONTACT</h4>
              <p>À travers le formulaire de contact, nous recueillons :</p>
              <ul className="list-group text-body">
                <li className="list text-light">votre nom</li>
                <li className="list">votre prénom</li>
                <li className="list">votre adresse mail</li>
                <li className="list">
                  votre qualité (professionnel/ particulier/ journaliste)
                </li>
                <li className="list">
                  des informations sur le bâtiment de votre projet : hauteur et
                  localisation
                </li>
                <li className="list">
                  des informations sur la nature de votre projet : nombre de
                  sites, existant ou en construction
                </li>
              </ul>
            </div>
            <hr />
            <div className="col-md-8">
              <p>
                Les informations recueillies sur ce formulaire sont enregistrées
                dans un fichier informatisé par WIND my ROOF. Ces données sont
                collectées dans le but unique de vous recontacter ponctuellement
                par mail afin de répondre à votre demande ou d’estimer la
                pertinence de votre projet. Ces données ne seront en aucun cas
                soumises à une utilisation commerciale. La base légale du
                traitement est le consentement.
              </p>
            </div>
            <div className="col-md-8">
              <hr />
              <p>
                Les données marquées par un astérisque dans le questionnaire
                doivent obligatoirement être fournies. Dans le cas contraire,
                nous ne pourrons pas vous recontacter ou estimer la pertinence
                de votre projet d’installation de WindBox.
              </p>
            </div>
            <div className="col-md-8">
              <hr />
              <p>
                Les données collectées seront communiquées aux seuls
                destinataires suivants : WIND my ROOF.{" "}
              </p>
            </div>
            <div className="col-md-8">
              <hr />
              <p>Les données sont conservées pendant une durée de 12 mois.</p>
            </div>
            <div className="col-md-8">
              <hr />
              <p>
                Vous pouvez accéder aux données vous concernant, les rectifier,
                demander leur effacement ou exercer votre droit à la limitation
                du traitement de vos données. Vous pouvez retirer à tout moment
                votre consentement au traitement de vos données et vous pouvez
                également vous opposer au traitement de vos données.{" "}
              </p>{" "}
            </div>

            <div className="col-md-8">
              <hr />
              <p>
                Pour exercer ces droits ou pour toute question sur le traitement
                de vos données dans ce dispositif, vous pouvez contacter
                contact@windmyroof.fr, 80 Boulevard de Picpus, 75012 Paris{" "}
              </p>{" "}
            </div>
            <div className="col-md-8">
              <hr />
              <p>
                Si vous estimez, après nous avoir contacté, que vos droits «
                Informatique et Libertés » ne sont pas respectés, vous pouvez
                adresser une réclamation à la CNIL.
              </p>{" "}
            </div>
            <div className="col-md-8">
              <h2>COOKIES</h2>
              <p>
                Notre site Web des cookies pour les choix de navigation et de
                langue afin d’optimiser votre expérience. Vous pouvez retrouver
                notre politique de cookie ici.{" "}
              </p>{" "}
            </div>
            <div className="col-md-8">
              <h2>CONTENU EMBARQUÉ DEPUIS D’AUTRES SITES</h2>
              <p>
                Les articles de ce site peuvent inclure des contenus intégrés
                (par exemple des vidéos, images, articles…). Le contenu intégré
                depuis d’autres sites se comporte de la même manière que si le
                visiteur se rendait sur cet autre site.
              </p>{" "}
              <p>
                Ces sites web pourraient collecter des données sur vous,
                utiliser des cookies, embarquer des outils de suivis tiers,
                suivre vos interactions avec ces contenus embarqués si vous
                disposez d’un compte connecté sur leur site web.
              </p>{" "}
            </div>

            <div className="col-md-8">
              <h2>STATISTIQUES ET MESURES D’AUDIENCE</h2>
              <p>
                Dans le cadre d’un suivi d’audience, nous pouvons être amenés à
                compter les visiteurs du site Web www.windmyroof.com. Ce
                comptage est anonyme et purement informatif, à destination de
                l’équipe de WIND my ROOF.
              </p>{" "}
            </div>

            <div className="col-md-8">
              <h2>Utilisation et transmission de vos données personnelles</h2>
              <p>
                Vos données ne seront accessibles qu’aux membres de l’équipe
                WIND my ROOF en charge de vous répondre et ne seront en aucun
                cas soumises à une utilisation commerciale.
              </p>{" "}
            </div>

            <div className="col-md-8">
              <h2>Durées de stockage de vos données</h2>
              <p>
                Nous conservons vos données pendant une durée de 12 mois à
                compter de l’envoi du formulaire mais nous vous invitons à nous
                recontacter régulièrement pour avoir des news sur le projet{" "}
                <span role="img" aria-labelledby="smail">
                  🙂
                </span>{" "}
              </p>
            </div>
            <div className="col-md-8">
              <h2>Les droits que vous avez sur vos données </h2>
              <p>
                Vous pouvez demander la suppression des données personnelles
                vous concernant. Cela ne prend pas en compte les données
                stockées à des fins administratives, légales ou pour des raisons
                de sécurité.
              </p>{" "}
            </div>

            <div className="col-md-8">
              <h2>Informations de contact</h2>
              <p>
                Pour toute demande concernant vos données, merci d’adresser un
                mail à contact@windmyroof.com en précisant l’objet de votre
                demande.
              </p>{" "}
            </div>
            <div className="col-md-8">
              <h2>Informations supplémentaires</h2>
              <p>
                Pour plus d’informations, nous vous invitons à consulter les
                mentions légales.
              </p>{" "}
            </div>
          </aside>
        </div>
      </main>
    </Fragment>
  );
};

export default PolitiqueProtectionDonnees;
