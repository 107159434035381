import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../assets/styles/Pages_css/Mes_sites.css";
import Navbar from "../../components/Navbar";
import userService from "../../services/users";
import { getDataCucule } from "../../store/actions/data.action";
import { login } from "../../store/actions/user.action"; 
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../store/actions/user.action";
const Mes_Site = () => {
  const dispatch = useDispatch();
  const userstates = useSelector((state) => state.user);
  const newData = [].concat(...userstates.listsite);
  const newDataEol = [].concat(...userstates.eolienne);
  const date = new Date();
  // const now = new Date();
  let day = (date.getMonth(), 1);
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `0${day}/0${month}/${year}`;
  const { t } = useTranslation();
  const { id_User } = useParams();
  useEffect(() => {
    const detaileUserSite = async () => {
      try {
        const result = await userService.getById(id_User);
        const dataCucule = result.data.CalculSite;
        const user = result.data.user;
        dispatch(login(user));
        dispatch(getData(user));
        dispatch(getDataCucule(dataCucule));
      } catch (error) {}
    };
    detaileUserSite();
    dispatch(
      getData({
        id: id_User,
      })
    );
  }, [id_User]);
  const sum = userstates.CalculSite.reduce((acc, curr) => acc + curr, 0);
  const resul = newDataEol.filter(function (o1) {
    return newData.some(function (o2) {
      return o1.site_id === o2.id_Site; // return the ones with equal i
    });
  });
  return (
    <Fragment>
      <div className="sit-details">
        <Navbar />
        {/* <div> */}
        <Helmet>
          <title> Eolienne solaire</title>
          <meta name="description" content=" Éolienne solaire" />
        </Helmet>
        <div className="services_mes_site">
          {userstates.user.map((item) => (
            <h1 key="{item}" className="py-1">
              {t("windbox")} {item.firstname} !
            </h1>
          ))}
          <div className="aline_shadow"></div>
        </div>
        {/*  chiffre clés */}
        {/* <div> */}
        {/* className="Copters_container_heder" */}
        <div className="container">
          <h2 className="py-22">{t("Chiffrecles")}</h2>
        </div>
        <div className="Copters_container_heder">
          <div className="Copters_container container">
            <div className="pulse-anim1">
              <p>{newData.length ? newData.length : 0} </p>
              <h1>{t("Projectsactifs")} </h1>
            </div>
            <div className="pulse-anim2">
              <p>{resul.length ? resul.length : 0} </p>
              <h1>{t("WindBoxinstales")}</h1>
            </div>
            <div className="pulse-anim3">
              <p>{sum.toFixed(2)}Kwh</p>
              <h1>
                {t("production")} {currentDate}{" "}
              </h1>
            </div>
            <div className="pulse-anim3">
              <p>copter</p>
              <h1>{t("TotaleCo")}</h1>
            </div>
          </div>
        </div>
        {/* <div className="circle1"></div>
        <div className="circle2"></div> */}
      </div>
    </Fragment>
  );
};
export default Mes_Site;
