import userService from "../../services/users";
export const getData = (id_User) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });
    const response = await userService.getById(id_User);
    const user = [];
    const listsite = [];
    const CalculSite = [];
    const eolienne = []
    for (let i = 0; i < response.data.CalculSite.length; i++) {
      CalculSite.push(response.data.CalculSite[i].value);
    }
    listsite.push(response.data.listsite);
    eolienne.push(response.data.eolienne);
    user.push(response.data.user);
    dispatch({
      type: "GET",
      payload: {
        listsite,
        user,
        CalculSite,
        eolienne,
      },
    });
  } catch (error) {
    dispatch({
      type: "AWAITING_USER",
    });
  }
};
export const getUsers = (user) => {
  return {
    type: "GET",
    payload: user,
  };
};
export const logout = () => {
  return {
    type: "LOGOUT",
  };
};
export const login = (user) => {
  return {
    type: "LOGIN",
    payload: user,
  };
};
