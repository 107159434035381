import React, { Fragment, useState } from "react";
import "../../assets/styles/Pages_css/Maintenance.css";
import Navbar from "../../components/Navbar";
import WindBox from "../../assets/sources/images/WindBox.png";
import { Helmet } from "react-helmet";
import NoMaintenance from "../../components/partials/NoMaintenance/ NoMaintenance";
function Maintenance() {
  const [maintenace] = useState(true)
  return (
    <Fragment>
      <Helmet>
            <title>Eolienne particulier</title>
            <meta name="description" content="eolienne particulier" />
        </Helmet>
      <Navbar />
      {maintenace ? (
       <NoMaintenance/>
      ) : ( 
      <div className="services_maintence_first">
        <div className="services-maintence ">
          <h1 className="py-5">Maintenace</h1>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6  col-sm-6">
                <div className="box-maintence">
                  <h4> Nom de project</h4>
                  <h5> Prochaine visite</h5>
                  <p>02/06/2022 </p>
                  <h6> Dernière visite.</h6>
                  <p>02/06/2022 </p>
                  <p>Lorem Ipsum est simplement du faux texte employé</p>
                </div>
              </div>
              {/*  */}
              <div className="col-lg-3 col-md-6  col-sm-6">
                <div className="box-maintence">
                  <h4> Nom de project</h4>
                  <h5> Prochaine visite</h5>
                  <p>02/06/2022 </p>
                  <h6> Dernière visite.</h6>
                  <p>02/06/2022 </p>
                  <p>Lorem Ipsum est simplement du faux texte employé</p>
                </div>
              </div>
              {/*  */}
              <div className="col-lg-3 col-md-6  col-sm-6">
                <div className="box-maintence">
                  <h4> Nom de project</h4>
                  <h5> Prochaine visite</h5>
                  <p>02/06/2022 </p>
                  <h6> Dernière visite.</h6>
                  <p>02/06/2022 </p>
                  <p>Lorem Ipsum est simplement du faux texte employé</p>
                </div>
              </div>
              {/*  */}
              <div className="col-lg-3 col-md-6  col-sm-6">
                <div className="box-maintence">
                  <h4> Nom de project</h4>
                  <h5> Prochaine visite</h5>
                  <p>02/06/2022 </p>
                  <h6> Dernière visite.</h6>
                  <p>02/06/2022 </p>
                  <p>Lorem Ipsum est simplement du faux texte employé</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="alert-box">
            <div>
              <img
                className="wind-en-maintenace"
                src={WindBox}
                alt="éolienne_panneau_solaire"
              />
              <p> Project N°</p>
            </div>
            <h2>La windBox est arrêtée </h2>
          </div>
          <span className="wind_en_maintenace_p">Quelle est la maintenances à faire. En bref</span>
        </div>
          </div>
          )}
    </Fragment>
  );
}
export default Maintenance;
