import React, { Fragment, useEffect, useState, useRef, useMemo } from "react";
import Navbar from "../../components/Navbar";
import "../../assets/styles/Pages_css/site_details.css";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dataService from "../../services/data";
import { GetAppOutlined } from "@material-ui/icons";
import Fetching from "../../components/Fetching";
import { Helmet } from "react-helmet";
import {
  getData,
  getData2,
  getData3,
  getData4,
  getData5,
} from "../../store/actions/site.action";
// reducer
import { useDispatch, useSelector } from "react-redux";

toast.configure();
function DetailleSite() {
  const dispatch = useDispatch();
  const datasState = useSelector((state) => state.site);
  const newDataEol = [].concat(...datasState.listeeolienne);
  const newDataSite = [].concat(...datasState.site);
  const totalculculEolEtPano = [].concat(...datasState.totalcalculations);
  const totalPanneauxDatacalculations = [].concat(
    ...datasState.totalPanneauxDatacalculations
  );
  const totalEolienneDatacalculations = [].concat(
    ...datasState.totalEolienneDatacalculations
  );
  const [chartDataDownlod, setchartDataDownlod] = useState("");

  const [mindate, setMindate] = useState("");
  const [maxdate, setMaxdate] = useState("");
  const { id_Site } = useParams();

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const chartRef = useRef(null);

  // const chatref = useRef(null)

  let datas = [];
  const csvLink = useRef();
  // const [lodding ] = useState(false);
  const getData5Memoized = useMemo(() => {
    return (id) => {
      dispatch(getData5({ id }));
    };
  }, [dispatch]);

  // export default Chart;
  useEffect(() => {
    // const chart = chartRef.current;
    const chart = chartRef.current.chartInstance;
    chart.canvas.parentNode.style.height = "80%";
    chart.canvas.parentNode.style.width = "95%";
    chart.options.responsive = true;
    chart.options.maintainAspectRatio = false;
    const getDetails = async () => {
      try {
        getData5Memoized(id_Site);
      } catch (error) {
 
      }
    };
    getDetails();

    // setlodding(false)
  }, [id_Site, getData5Memoized]);
  const handleClick = async () => {
    try {
      datas = await dataService.downlod(id_Site, mindate, maxdate);
      setchartDataDownlod(datas.data);
      csvLink.current.link.click();
      toast.success(
        "vous avez  Selectioné deux data vous pouvez de maitainon téléchercge ",
        { position: toast.POSITION.TOP_CENTER }
      );
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const fetchDatas = (id_Site) => {
    dispatch(
      getData({
        id: id_Site,
      })
    );
  };
  const fetchDataOfOnehourGO = (id_Site) => {
    dispatch(
      getData5({
        id: id_Site,
      })
    );
  };
  const fetchDatasOfOneYear = (id_Site) => {
    dispatch(
      getData2({
        id: id_Site,
      })
    );
  };
  const fetchDataMorethanOneYears = (id_Site) => {
    dispatch(
      getData3({
        id: id_Site,
      })
    );
  };
  const fetchDataOfToDay = (id_Site) => {
    dispatch(
      getData4({
        id: id_Site,
      })
    );
  };


  return (
    <Fragment>
      <Navbar />
      {/* {datasState.loading ? (
        <span>
          <Fetching />
        </span>
        ) : ( */}
          {datasState.loading ? (
        <span>
          <Fetching />
        </span>
      ) : null }
      <div className="site_details">
        <div className="site_titre">
          <Helmet>
            <title>Eolienne de toit</title>
            <meta name="description" content="Éolienne de toit" />
          </Helmet>
          <h1 className="header_titre">Mon Site</h1>
          <div className="header_line"></div>
        </div>
        <div className="details_element">
          <section className="agenda_form">
            <div className="site_details_one">
              <div>
                <h4>Information de site</h4>
              </div>
              <section className="dt_enterprise ">
                {newDataSite.map((item, index) => (
                  <ul key={index} className="list">
                    <li className="list1">Nom de site {item.nomSite}</li>
                    <li className="list1">Adresse {item.adresseSite}</li>
                    <li className="list1">L'atitude {item.latitudeSite}</li>
                    <li className="list1">Longitude {item.longitudeSite}</li>
                    <li>
                      Nombre windbox {newDataEol.length ? newDataEol.length : 0}
                    </li>
                  </ul>
                ))}
              </section>
              <div className="newDataSite">
                {newDataSite.length
                  ? newDataSite.le
                  : "Ce site  n'a pas consu de donne dans cette perdid"}
              </div>
            </div>
            {/*  */}
            <div className="site_details_two">
              <h4>information sur les donnes</h4>
              <div className="alert_boxs_2">
                <span className="calcule_alert_animation_1">
                  <p>
                    {totalEolienneDatacalculations.length
                      ? totalEolienneDatacalculations
                      : 0}
                  </p>
                  <span className="calcule_alert_box1">
                    production éolienne
                  </span>
                </span>
                <span className="calcule_alert_animation_2">
                  <p>
                    {totalculculEolEtPano.length ? totalculculEolEtPano : 0}
                  </p>
                  <span className="calcule_alert_box1">production totale</span>
                </span>
                <span className="calcule_alert_animation_3">
                  <p>
                    {totalPanneauxDatacalculations.length
                      ? totalPanneauxDatacalculations
                      : 0}
                  </p>
                  <span className="calcule_alert_box1">production solaire</span>
                </span>
              </div>
              <div className="header_in_chart_2">
                <label>
                  De :
                  <input
                    name="mindate"
                    type="date"
                    className="input_detai-site"
                    data-date-format="DD MMMM YYYY"
                    placeholder="mettez le mindate"
                    value={mindate}
                    onChange={(e) => setMindate(e.target.value)}
                    required
                    minLength="4"
                    ref={inputRef1}
                  />
                </label>
                <label>
                  à :
                  <input
                    name="maxdate"
                    type="date"
                    className="input_detai-site"
                    placeholder="mettez le maxdate"
                    value={maxdate}
                    onChange={(e) => setMaxdate(e.target.value)}
                    required
                    minLength="4"
                    ref={inputRef2}
                  />
                </label>
                <div className="button_two">
                  <button className=" square sub" onClick={handleClick}>
                    {" "}
                    download |<GetAppOutlined />
                  </button>
                </div>
                <span className="b_icone">
                  <CSVLink
                    data={chartDataDownlod}
                    asyncOnClick={true}
                    filename="dataSite.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  ></CSVLink>
                </span>
              </div>
            </div>
          </section>
          {/* /////// */}
          <div className="site_details_there">
            <div className="header_in_chart">
              <h4 className="a_titre">Information technique</h4>
            </div>
            <div>
              {/* {datasState.loading && (
                <span>
                  <Fetching />
                </span>
              )} */}
        
              <Line
                ref={chartRef}
                data={datasState.data}
                width={100}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
            <div className="b_chart">
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => fetchDataOfOnehourGO(id_Site)}
              >
                1/h
              </button>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => fetchDataOfToDay(id_Site)}
              >
                24/h
              </button>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => fetchDatas(id_Site)}
              >
                1/M
              </button>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => fetchDatasOfOneYear(id_Site)}
              >
                1/an
              </button>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => fetchDataMorethanOneYears(id_Site)}
              >
                +1/an
              </button>
            </div>
          </div>
        </div>
      </div>
    
      {/* )} */}
      </Fragment>
      );
}
export default DetailleSite;
