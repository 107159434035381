const initalState = {
  loading: false,
  error: null,
  site: [],
  listeeolienne: [],
  totalcalculations: [],
  totalPanneauxDatacalculations: [],
  totalEolienneDatacalculations: [],
  data: {
    PanneauxlebelsTotale: [],
    Panneauxlebels: [],
    labels: [],
    datasets: [
      {
        PanneauxDataTotale: [],
        data1: [],
        PanneauxData: [],
      },
    ],
  },
  
};
const siteReducer = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "AWAITING":
      return {
        ...state,
        loading: true,
        
      };
    // case "GET_SITE":
    //   return {
    //     site: action.payload,
    //     listeeolienne: payload.listeeolienne,
    //     isLogged: false,
    //   };
      case "SUCCESS":
        return {
          ...state,
          loading: false,
          site: payload.site,
          listeeolienne: payload.listeeolienne,
          totalcalculations: payload.totalcalculations,
          totalPanneauxDatacalculations: payload.totalPanneauxDatacalculations,
          totalEolienneDatacalculations: payload.totalEolienneDatacalculations,
          data: {
            labels: payload.PanneauxlebelsTotale,
            datasets: [
              {
                label: "total",
                data: payload.PanneauxDataTotale,
                backgroundColor: "rgba(0, 167, 211, 0.4)",
                borderColor: "#00a7d3",
                pointBorderColor: "#00a7d3",
              },
              {
                label: "Eole",
                data1: payload.data,
                backgroundColor: "rgba(37, 89, 165, 0.4)",
                borderColor: "rgba(37, 150, 190, 0.5)",
                pointBorderColor: "rgba(37, 150, 190, 0.7)",
                borderWidth: 2,
                pointRadius: 3,
              
              },
              {
                label: "Pano",
                data: payload.PanneauxData,
                backgroundColor: "rgba(68,175,0, 0.4)",
                borderColor: "#00A8AC",
                pointBorderColor: "#00A8AC",
              },
              
            ],
          },
          
        };
    case "REJECTED":
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case "UPDATEING_STATE":
      return {
        ...state,
        loading: false,
        site: payload.site,
        listeeolienne: payload.listeeolienne,
        totalcalculations: payload.totalcalculations,
        totalPanneauxDatacalculations: payload.totalPanneauxDatacalculations,
        totalEolienneDatacalculations: payload.totalEolienneDatacalculations,
        data: {
          PanneauxlebelsTotale: payload.PanneauxlebelsTotale,
          labels: payload.labels,
          Panneauxlebels: payload.Panneauxlebels,
          datasets: [
            {
              label: "KW",
              PanneauxlebelsTotale: payload.PanneauxlebelsTotale,
              data1: payload.data,
              data: payload.PanneauxData,
              borderColor: "rgba(238,175,0, 0.5)",
              pointBorderColor: "rgba(238,175,0, 0.7)",
            },
          ],
        },
      };
    default:
      return state;
  }

};
export default siteReducer;
