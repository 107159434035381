import React, { useState } from "react";
import { Link } from "react-scroll";
import { HomeNav } from "../../components/partials/HomeNav/HomeNav";
import "../../assets/styles/Pages_css/passwordOtpForm.style.css";
import userService from "../../services/users";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
toast.configure();
const reset_password = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const history = useHistory();
  const { token } = useParams();
  const handleClick = async () => {
    try {
      const res = await userService.restPassword(token, password1, password2);
      //const user = data;
      toast.success(res.data.message, { position: toast.POSITION.TOP_CENTER });
      history.push("/login");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <>
      <div className="body">
      <div className="color-overlay"></div>
        <HomeNav />
        <Helmet>
            <title>eolienne particulier</title>
            <meta name="description" content="Eolienne" />
        </Helmet>
        <div className="login-wrap">
          <div className="login_html_forgot_password_r">
            <label className="tab_forgot_password">Mot de passe oublié</label>
            <div className="login_group_forgot_password">
              <form>
                <div className="group_forgot_password">
                  <label htmlFor="user" className="label_forgot_password">
                    nouvaux password
                  </label>
                  <input
                    name="password1"
                    type="password1"
                    className="input"
                    placeholder="nouvaux password"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                    required
                    minLength="4"
                  />
                </div>
                <div className="group_forgot_password">
                  <label htmlFor="user" className="label_forgot_password">
                    confurmer votre mot de pass
                  </label>
                  <input
                    name="password2"
                    type="password2"
                    className="input"
                    placeholder="confurmer votre mot de pass"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    required
                    minLength="4"
                  />
                </div>
              </form>
              <div className="form-group group-flex">
                <Link onClick={handleClick} className="btn btn-green">
                  Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};
export default reset_password;
