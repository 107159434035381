import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/styles/Components_css/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { BsBuilding } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getData5 } from "../store/actions/site.action";
const Navbar = () => {
  const dispatch = useDispatch();
  const usersState = useSelector((state, user) => state.user);
  const newData = [].concat(...usersState.listsite);
  const [show, setShow] = useState(true);
  var id_User = localStorage.getItem("user");
  const refAndGet = (id_Site) => {
      dispatch(
        getData5({
          id: id_Site,
        })
      );
  };
  const { t } = useTranslation();
  const controlNavbar = () => {
    if (window.scrollY > 55) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  useEffect(() => {
    newData.forEach((item) => {
      localStorage.setItem("item", JSON.stringify(item.nomSite));
    });
  }, [newData]);
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  return (
    <>
      <div className={`nav ${show && 'nav__blue'}`}>
        <nav
          className={`navbar navbar-expand-lg navbar-light fixed-top ${
            show && "nav__blue"
          }`}
        >
          <div className={`container ${show && "container"}`}>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon icon={faBars} style={{ color: "none" }} />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to={`/user/${id_User}`} className="nav-link f1">
                    Accueil
                  </Link>
                </li>
                <div className="dropdown ">
                  <li className="nav-item ">
                    <Link to={`/user/${id_User}`} className="nav-link f1">
                      {t("MesSites")}
                    </Link>
                  </li>
                  <div className="dropdown-content">
                    <div className="c-headerCel2User__infos">
                      {newData.map((i) => (
                        <Link
                          className="navlink info_perso"
                          key={i.id_Site}
                          to={`/site/${i.id_Site}`}
                          onClick={() => refAndGet(`${i.id_Site}`)}
                          >
                          <BsBuilding className="view-icon" />
                          <p className="c-headerCel2User__infosName">
                            {i.nomSite}
                          </p>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <li className="nav-item">
                  <Link to="/Contacte" className="nav-link">
                    {t("NousContacter")}
                  </Link>
                </li>
                <div className="dropdown">
                  <li className=" nav-item">
                    <Link to="#" className="nav-link f1">
                      {t("MonCompte ")}
                    </Link>
                  </li>
                  <div className="dropdown-content ">
                    {usersState.user.map((item) => (
                      <div key={item} className="c-headerCel2User__infos">
                        <p className="c-headerCel2User__infosName">
                          {item.firstname}!
                        </p>
                        <p className="c-headerCel2User__infosName">
                          <FaHome color="black" className="view-icon" />{" "}
                        </p>
                        <p className="c-headerCel2User__infosName">
                          {item.email}
                        </p>
                        <p className="c-headerCel2User__infosName">
                          {item.number}
                        </p>
                      </div>
                    ))}
                    <div className="c-headerCel2User__infos">
                      <Link className="navlink info_perso" to="/profie">
                        <CgProfile color="black" className="view-icon" />
                        <p>{t("Infopersonnel")}</p>
                      </Link>
                    </div>
                    <div className="c-headerCel2User__infos">
                      <Link
                        className="navlink info_perso2"
                        onClick={() => {
                          localStorage.clear();
                          dispatch({ type: "CLEAR" });
                        }}
                        to="/"
                      >
                        <MdLogout color="black" className="view-icon" />
                        <p>{t("Deconection")}</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
export default Navbar;
