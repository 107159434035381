import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/styles/Components_css/Footer.css";
import yt1 from "../assets/sources/images/yt1.png";
import tw1 from "../assets/sources/images/tw1.png";
import in1 from "../assets/sources/images/in1.png";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p> WIND my ROOF 80 boulevard de Picpus 75012, Paris, France</p>
            </div>
            <div className="d-flex">
              <a href="77785455-65445">+00000000000000)</a>
            </div>
            <div className="d-flex">
              <a href="/contacte"> contact@windmyroof.com</a>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-item-center">
            <div className="d-flex justify-content-center">
              <div>
                <a
                  href="https://twitter.com/WINDmyROOF?ref_src=twsrc%5Etfw"
                  className="youtube"
                >
                  <img className="mx-3" src={tw1} alt="logotwitter" width="40" height="40"  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/wind-my-roof"
                  className="youtube"
                >
                  <img className="mx-3" src={in1} alt="logolinkedin" width="40" height="40"  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/channel/UCpDWOxMUdT9J76DzFFLrvMQ"
                  className="youtube"
                >
                  <img className="mx-3" src={yt1} alt="logoyoutube" width="40" height="40"  />
                </a>
              </div>
            </div>
            <p className="pt-3 text-align-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;WIND my ROOF | All Rigths Reservd
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
