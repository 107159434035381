import { combineReducers } from "redux";
import siteReducer from "./siteReducer";
import userReducer from "./userReducer";
import dataReducer from "./data.Reducer";
const rootReducer = combineReducers({
  site: siteReducer,
  user: userReducer,
  dataCucule: dataReducer,
});
export default rootReducer;
