import api from "./api";
const siteService = {
  // getById: async (id_Site) => {
  //   return await api.get(`datas/data/of_hour/site/${id_Site}`)
  //   // return await api.get(`users/data/user/${id_Site}`)
  // } ,
  // getSiteById: async ({id: id_Site}) => {
  //   return await api.get(`sites/site/${id_Site}`)
  // },
  // getById: async ( {id: id_Site}) => {
  //   return await api.get("/datas/data/of_day/site/" + id_Site);
  // },
  getByIdOfDay: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_day/site/" + id_Site);
  },
  getByIdOfOnHourGo: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_hour/site/" + id_Site);
  },
  getByIdOfWeek: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_week/site/" + id_Site);
  },
  getByIdOfMonth: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_year/site/" + id_Site);
  },
  getByIdOfYear: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_years/site/" + id_Site);
  },
};
export default siteService;