import React, { useContext, useState } from "react";
import { Link } from "react-scroll";
import { HomeNav } from "../../components/partials/HomeNav/HomeNav";
import "../../assets/styles/Pages_css/passwordOtpForm.style.css";
import userService from "../../services/users";
import { UserContext } from "../../App";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
toast.configure();
const PasswordOtpForm = () => {
  const { dispatch } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const routeChange = () => {
    let path = `/`;
    history.push(path);
  };
  const handleClick = async () => {
    try {
      const { data } = await userService.forgotPassword(email);
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("token", data.token);
      dispatch({ type: "USER", payload: data.user });
      toast.success("Vous avez reçu un mail vérifiez votre boîte  mail.", {
        position: toast.POSITION.TOP_CENTER,
      });
      history.push("/");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <>
      <div className="body_forgot_pass">
      <div className="color-overlay"></div>
        <HomeNav />
        <Helmet>
            <title>Eolienne</title>
            <meta name="description" content="Eolienne" />
        </Helmet>
        <div className="login-wrap">
          <div className="login_html_forgot_password">
            <label className="tab_forgot_password">
               {t("forgotpasswordtitre") }
            </label>
            <div className="login_group_forgot_password">
              <form>
                <div className="group_forgot_password">
                  <label htmlFor="user" className="label_forgot_password">
                    E-mail
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="input"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </form>
              <div className="form-group group-flex">
                <Link to="#" onClick={handleClick} className="btn btn-green">
                {t("forgotpasswordEnvoyer")}
                </Link>
                <Link onClick={routeChange} to="/">
                {t("forgotpasswordreture")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PasswordOtpForm;
