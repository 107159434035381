export const initialstate = {
  dataCucule: [],
  isLogged: true,
  error: null,
};
const reducer = (state = initialstate, action) => {
  switch (action.type) {
    case "GET_DataCucule":
      return {
        ...state,
        dataCucule: action.payload,
        isLogged: false,
      };
    case "ADD_SITE":
      return {
        ...state,
        employees: state.employees.concat(action.payload),
      };
    default:
      return state;
  }
};
export default reducer;
