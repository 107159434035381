import React from "react";
import "./page_not_found.css";

const page_not_found = () => {

  return (
    <>
      <div className="bodyNav_Page_not_found">
        <div className="containers">
          <h3>ERREUR404</h3>
          <p>La page que vous recherchez n'existe pas</p>
          <a className="button" href="https://trackmyroof.com/">
          Retour à la page d'accueil{" "}
          </a>
        </div>
      </div>
    </>
  );
};
export default page_not_found;
