export const initialState = {
  user: [],
  listsite: [],
  CalculSite: [],
  eolienne: [],
  token: null,
  loading: false,
  error: null,
  isAuthenticated: false,
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "AWAITING_USER":
      return {
        ...state,
        loading: true,
      };
    case "GET":
      return {
        ...state,
        user: payload.user,
        listsite: payload.listsite,
        CalculSite: payload.CalculSite,
        eolienne: payload.eolienne,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        loading: false,
      };
    default:
      return state;
  }
};
export default reducer;
