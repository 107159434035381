import React, { createContext, useReducer } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { initialState } from "./store/reducers/userReducer";
import Home from "./pages/Home/Home";
import Dashboard from "./pages/Dashboard/Dashboard";
import PasswordOtpForm from "./pages/password-reset/PasswordOtpForm";
import reset_password from "./pages/password-reset/reset_password";
import Mes_Site from "./pages/Mes_Sites/Mes_Site_Details";
import Maintenance from "./pages/Maintenance/Maintenance";
import Contact from "./pages/Contacte/Contact";
import Profile from "./pages/Profie/Profile";
import page_not_found from "./helper/error/page_not_found";
import DetailleSite from "./pages/Mes_Sites/DetailleSite";
import { combineReducers } from "redux";
import Footer from "./components/Footer";
import PolitiqueProtectionDonnees from "./pages/PolitiqueProtectionDonnees/PolitiqueProtectionDonnees";
export const UserContext = createContext();
const Routing = () => {
  function PrivateRoute(props) {
    if (localStorage.getItem("token")) {
      return <Route exact path={props.path} component={props.component} />;
    } else {
      return <Route render={() => <Redirect to="/" />} />;
    }
  }
  return (
    <>
   
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/PolitiqueProtectionDonnees" component={PolitiqueProtectionDonnees} />
        <PrivateRoute exact path="/help" component={Dashboard} />
        <Route exact path="/forgot-password" component={PasswordOtpForm} />
        <Route exact path="/reset-password/:token" component={reset_password} />
        <PrivateRoute exact path="/user/:id_User" component={Mes_Site} />
        <PrivateRoute exact path="/Maintenance" component={Maintenance} />
        <PrivateRoute exact path="/Contacte" component={Contact} />
        <PrivateRoute exact path="/profie" component={Profile} />
        <PrivateRoute exact path="/site/:id_Site" component={DetailleSite} />
        <Route exact path="/no" component={page_not_found} />
        <Route exact path="*" component={page_not_found} />
      </Switch>
      <Footer />
    </>
  );
};
function App() {
  const [state, dispatch] = useReducer(combineReducers, initialState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <BrowserRouter basename="/bMKbMfy5ZgmNfKcDjXkDk">
        <Routing />
      </BrowserRouter>
    </UserContext.Provider>
  );
}
export default App;
