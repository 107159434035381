import React, { useState } from "react";
import "../../assets/styles/Pages_css/Home.css";
import helice from "../../assets/sources/images/helice.png";
import logo2 from "../../assets/sources/images/logo2.png";
import helicesecond from "../../assets/sources/images/helice.png";
// import CookiePopUp from "../../components/partials/CookiePopUp/CookiePopUp";
import { useTranslation } from "react-i18next";
import { Us, Fr } from "react-flags-select";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useDispatch } from "react-redux";
import userService from "../../services/users";
import { toast } from "react-toastify";
import { eye } from "react-icons-kit/feather/eye";
import { login } from "../../store/actions/user.action";
import { Icon } from "react-icons-kit";
import ReCAPTCHA from "react-google-recaptcha";



const lngs = [
  { code: "fr", native: <Fr /> },
  { code: "en", native: <Us /> },
];
const Home = () => {
  //  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  // const serviceID = `${process.env.REACT_APP_SITE_KEY}`;
  const [error, setError] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleClick = async () => {
    try {
      const { data } = await userService.login(email, password);
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", data.user.id_User);
      const user = data.user;
      dispatch({ type: "USER", payload: data.user });
      const tokenCheker = await userService.chekRobot(token);
      if (!token) {
        setError(tokenCheker.data);
        return;
      }
      setError("");
      dispatch(login(user));
      const id_User = localStorage.getItem("user");
      toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
      history.push(`/user/${id_User}`);
    
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };



  

  
  
  return (
    <>
      <div className="body">
        <div className="main_in_home_nave container">
          <div className="dropdowns left">
            <ol className="nav-item">
              <Link
                to="/"
                className="f2">
                {t("MesLangue")}
              </Link>
            </ol>
            <div className="dropdown-contents">
              {lngs.map((lng, i) => {
                const { code, native } = lng;
                return (
                  <Link
                    key={i}
                    to="#"
                    className="language"
                    onClick={() => handleTrans(code)}
                  >
                    {native}
                  </Link>
                );
              })}
            </div>
          </div>
          {/* / */}
          <div className="lignes_as_nav_home"></div>
          <div className="first_container_nav_second">
            <img
              className="Home_Nav_logo_un_home"
              src={helice}
              alt="windmyrooflogo"
            />
          </div>
        </div>
        {/*  */}
        <div className="seconde_container_nav">
          <div className="secconde_container_logo_track">
            <img className="Home_Nav_logo_track" src={logo2} alt="eolienne" width="512" height="347" />
            <img
              className="Home_Nav_logo_track_wind"
              src={helicesecond}
              alt="éolienne domestique"
            />
          </div>
        </div>
        <div className="twet_bto_one container">
          <div className="">
            <div className="login-html">
              <label className="tab">{t("bienvenue")}</label>
              <div className="login-form">
                <form>
                  <div className="group">
                    <label htmlFor="email" className="label">
                      E-mail
                    </label>
                    <input
                      name="email"
                      type="email"
                      autoComplete="on"
                      className="input"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="password" className="label">
                      {t("password")}
                    </label>
                    <div className="input_password_client input_pass">
                      <input
                        name="password"
                        type={type}
                        className="input"
                        placeholder="Mot de passe"
                        autoComplete="on"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span onClick={handleToggle}>
                        <Icon icon={icon} size={25} />
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ transform: "scale(0.85)", transformOrigin: "0 0" }}
                  >
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SECRET_KEY}
                      onChange={(token) => setToken(token)}
                      onExpired={(e) => setToken("")}
                    />
                  </div>
                  <div className="form-group">
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                  <div className="form-group group-flex">
                    <Link
                      to="#"
                      onClick={handleClick}
                      className="btn btn-green"
                    >
                      {t("Login")}
                    </Link>
                    <Link to="/forgot-password" className="form-link">
                      {t("passwordforgot")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <CookiePopUp  /> */}
      </div>
    </>
  );
};
export default Home;
