import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    fr: {
      translation: {
        bienvenue: "S'IDENTIFIER",
        password: "Mot de passe",
        passwordforgot: "mot de Passe oublié ?",
        windbox: "Bienvenue",
        Login: "connexion",
        MesLangue:"Langages",
        connecter: "se connecter",
        forgotpasswordtitre: "Mettez votre adresse mail", 
        forgotpasswordEnvoyer: "Envoyer",
        forgotpasswordreture: "Connexion ?",
        Chiffrecles: "Chiffre-clés",
        Projectsactifs: "Projets actifs",
        WindBoxinstales: "WindBox installées",
        production:"Production depuis",
        TotaleCo: "Totale Co2",
        // Mes Sites
        MesSites: "Mes Sites",
        Maintenance: "Maintenance",
        NousContacter: "Nous contacter",
        MonCompte: "Mon Compte ",
        Deconection: "Se déconnecter",
        Infopersonnel: "Infos personnelles",
        // Contact page
        Contact: "Contact",
        need: "Une question, un besoin ? ",
        ask: "Demandez nous ce que vous voulez ! Vous pouvez aussi nous envoyer un à contact@windmyroof.com et nous ferons de notre mieux répondre.",
        placeholderNom: "Nom",
        // CookiePopUp
        CookiePopUpPone: "Windmyrooof utilise des cookies pour fournir les fonctionnalités nécessaires du site Web, améliorer votre expérience et analyser notre trafic. En utilisant notre site Web, vous acceptez notre politique de confidentialité et l'utilisation de nos cookies..",
        Accepterlescookies: "Accepter les cookies",
        Refuserlescookies :"Refuser les cookies"
      },
    },
    en: {
      translation: {
        bienvenue: "SIGN IN",
        Login:"Login",
        password: "password",
        passwordforgot: "Forgot your password ?",
        windbox: "Welcome",
        connecter: "Log in",
        // forgotpassword pages
        forgotpasswordtitre: "Put your email address",
        forgotpasswordEnvoyer: " Submit",
        forgotpasswordreture: "Log in ?",
        // aftter connection dashbord page
        Chiffrecles: "Key figures",
        Projectsactifs: "Active projects",
        WindBoxinstales: "WindBoxes installed",
        production: "Production since installation",
        TotaleCo: "Total Co2",
        MesSites: "My Sites",
        Maintenance: "Maintenance",
        NousContacter: "Contact us",
        MonCompte: "my account",
        Deconection: "Sign out",
        Infopersonnel: "Personal info",
        //   Contact page
        Contact: "Contact",
        need: "A question, a need?",
        ask:"Ask us what you want! You can also email us at contact@windmyroof.com and we will do our best to respond.",
        placeholderNom: "Name",
        //CookiePopUp
        CookiePopUpPone: "Windmyrooof uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our privacy policy and the use of our cookies...",
        Accepterlescookies:"Accept cookies",
        Refuserlescookies :"Refuse cookies "

      },
    },
  },
});

export default i18n;
