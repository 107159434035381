import React, { Fragment, useState } from "react";
import "../../assets/styles/Pages_css/Contacte.css";
import Navbar from "../../components/Navbar";
import userService from "../../services/users";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Contact = () => {
  const [successMessage, setSuccessMessage] = useState("");
  //
  const [first_name, setFirst_Name] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [adresse, setAdresse] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const { t } = useTranslation();
  const onSubmit = async (e) => {
    try {
      const t = await userService.sendEmailContact(
        email,
        name,
        first_name,
        adresse,
        phone,
        description,
        subject,
      );
      setAdresse("")
      setDescription("")
      setFirst_Name("")
      setPhone("")
      setEmail("")
      setName("")
      setSubject("")
      setSuccessMessage(t.data.message)
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <Fragment>
      <Navbar />
      <div className="contact">
      <Helmet>
            <title>Panneaux solaires</title>
            <meta name="description" content="Panneaux solaires" />
        </Helmet>
        <div className="text-center">
          <h1>{t ("Contact")}</h1>
          <p>{t ("need")} =&gt; <Link to="/help">cic</Link> </p>
          <p>
          {t ("ask")}
          </p>
          <span className="sccese-massege">{successMessage}</span>
        </div>
        <div className="container ">
          <div className="row ">
            <div>
              <input
                type="text"
                className="form-control"
                placeholder={t ("placeholderNom")}
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <div className="line"></div>
              <input
                type="text"
                className="form-control"
                placeholder="prénom"
                name="first_name"
                value={first_name}
                required
                onChange={(e) => setFirst_Name(e.target.value)}
              />
              <div className="line"></div>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="line"></div>
              <input
                type="number"
                className="form-control"
                placeholder="Numéro de télép
                hone"
                name="phone"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="line"></div>
              <input
                type="text"
                className="form-control"
                placeholder="Adresse du bati
                ment"
                name="adresse"
                value={adresse}
                required
                onChange={(e) => setAdresse(e.target.value)}
              />
              <div className="line"></div>
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                name="subject"
                value={subject}
                required
                onChange={(e) => setSubject(e.target.value)}
              />
              <div className="line"></div>
              <textarea
                type="text"
                className="form-control"
                placeholder="Votre message 
                ici"
                name="description"
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              <div className="h">
              <input
                type="submit"
                onClick={onSubmit}
                className="btn btn-primary btn-main-offer content-btn"
                value="Envoyer"
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Contact;
